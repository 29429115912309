import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import Modal from "react-bootstrap/Modal";
import CookieConsent from "react-cookie-consent";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <footer
      className={`footer-area ${
        backgroundColorClass ? backgroundColorClass : ""
      } ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${extraFooterClass ? extraFooterClass : ""} ${
        spaceLeftClass ? spaceLeftClass : ""
      } ${spaceRightClass ? spaceRightClass : ""}`}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
            }`}
          >
            <div className="footer-widget mb-10 ml-10">
              <div className="footer-title">
                <h3>Company</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About us</Link>
                  </li>
                  {/* <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Store location
                    </Link>
                  </li> */}
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
            }`}
          >
            <div
              className={`${
                sideMenu ? "footer-widget mb-30" : "footer-widget mb-30 ml-10"
              }`}
            >
              <div className="footer-title">
                <h3>Useful Links </h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/returnandrefundpolicy"}
                    >
                      Return & Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacypolicy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/termsandconditions"}>
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shippingpolicy"}>
                      Shipping Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
                  </li>

                  <li>
                    <>
                      <a variant="primary" onClick={handleShow}>
                        Disclaimer
                      </a>

                      <Modal
                        show={show}
                        onHide={handleClose}
                        contentClassName="disclaimer"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <h4>Disclaimer :-</h4>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: 10 }}>
                          <p>
                            All third party trademarks (including logos and
                            icons) referenced by Creative Swag remain the
                            property of their respective owners. Unless
                            specifically identified as such, Creative Swag’s use
                            of third party trademarks does not indicate any
                            relationship, sponsorship, or endorsement between
                            Creative Swag and the owners of these trademarks.
                            All references by Creative Swag to third party
                            trademarks are to identify the corresponding third
                            party goods and/or services and intended to
                            constitute nominative fair use under applicable
                            trademark laws.
                          </p>
                        </Modal.Body>
                      </Modal>
                    </>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-4" : "col-lg-3 col-sm-6"
            }`}
          >
            <div
              className={`${
                sideMenu ? "footer-widget mb-30" : "footer-widget mb-30 ml-10"
              }`}
            >
              <div className="footer-title">
                <h3>Contact</h3>
              </div>
              <div className="mb-2">
                <i className="fa fa-map-marker-alt mr-3" />
                Creative SWAG <br />
                <p style={{ paddingLeft: "26px" }}>
                  11, 3rd Floor, Astitva Building, Railway Parallel Rd, Nehru
                  Nagar, Seshadripuram, Bengaluru, Karnataka 560020
                </p>
              </div>
              <a className="mb-2" href="tel:+919686202046">
                <i className="fa fa-phone-alt mr-2" />
                +91 9686202046
              </a>
              <br />
              <a className="mb-2" href="mailto:sales@a2rcreative.in">
                <i className="fa fa-envelope mr-2" />
                sales@a2rcreative.in
              </a>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-3 col-sm-6"
            }`}
          >
            {/* footer newsletter */}
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-10"
              sideMenu={sideMenu}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-3">
              <div className="footer__social">
                <a href="https://www.facebook.com/profile.php?id=100085894656985">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://twitter.com/A2rSupport">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/company/creative-swag/">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/creativeswag.in/">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>

            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              <FooterCopyright
                footerLogo="/assets/img/logo/logo.png"
                spaceBottomClass="mb-10"
              />
            </div>
            <div className="footer-menu">
              <a href="">Home</a>
              <a href="">Cookies</a>
              <a href="">FAQs</a>
              <a href="/blog">Blog</a>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "13px",
          borderRadius: "20px",
          width: "100px",
        }}
        expires={150}
      >
        <b>Do you like cookies?</b> &#x1F36A; We use cookies to ensure you get
        the best experience on our website.{" "}
      </CookieConsent>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
