import productReducer from "./productReducer";
import { combineReducers } from "redux";
import userReducer from "./sessionReducer";
import selectedImageReducer from "./selectedImageReducer";
import imagesData from "./imagesData";
import searchedData from "./searchedData";
import filterData from "./filterData";
import filteredData from "./filteredData";
import cartReducer from "./cartReducer";
import inquiryReducer from "./inquiryReducer";

const rootReducer = combineReducers({
  productData: productReducer,
  userData: userReducer,
  selectedImage: selectedImageReducer,
  imageData: imagesData,
  searchedData: searchedData,
  filterData: filterData,
  filteredData: filteredData,
  cartData: cartReducer,
  inquiryData: inquiryReducer,
});

export default rootReducer;
