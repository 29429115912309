import PropTypes from "prop-types";
import React from "react";

const SectionTitleShippingPolicyText = ({
  spaceTopClass,
  spaceBottomClass,
}) => {
  return (
    <div
      className={`welcome-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="welcome-content">
          <p>
            <h2>Shipping Policy</h2>
          </p>
          <p>
            Items in stock between 10 -15working days depending upon your PIN
            code.
          </p>
          <p>
            <strong>On order</strong>
          </p>
          <p>Will be discussed and finalized during order placement.</p>
        </div>
      </div>
    </div>
  );
};

SectionTitleShippingPolicyText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default SectionTitleShippingPolicyText;
