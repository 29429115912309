import { FETCH_FILTER_DATA } from "../actions/filteredData";

const initState = {
  filteredData: [],
};

const filteredData = (state = initState, action) => {
  if (action.type === FETCH_FILTER_DATA) {
    return {
      ...state,
      filteredData: action.payload,
    };
  }

  return state;
};

export default filteredData;
