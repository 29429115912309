import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { publicRequest } from "../../../axios/axios";

const MobileNavMenu = ({ strings }) => {
  const [categoryList, setcategoryList] = useState(null);
  const getGategoryList = async () => {
    await publicRequest
      .post("/mainCategory/categoryList", { isActive: 1 })
      .then((response) => {
        setcategoryList(response.data.data);
      });
  };
  function compareName(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const serial1 = a.serial;
    const serial2 = b.serial;

    let comparison = 0;

    if (serial1 > serial2) {
      comparison = 1;
    } else if (serial1 < serial2) {
      comparison = -1;
    }
    return comparison;
  }
  useEffect(() => {
    getGategoryList();
    return () => {
      setcategoryList([]);
    };
  }, []);
  categoryList && categoryList.sort(compareName);
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      {categoryList && categoryList.length > 0 && (
        <ul>
          {categoryList.map((item, index) => {
            return (
              <li className="menu-item-has-children" key={index}>
                <Link to={`/shop/category=${item.mainCategoryId}`}>
                  {item.productCategoryName}
                </Link>
                {item.subCategory && item.subCategory.length > 0 && (
                  <ul className="sub-menu">
                    {item.subCategory.map((item, index) => {
                      return (
                        <li className="menu-item-has-children" key={index}>
                          <Link to={`/shop/sub-category=${item.subCategoryId}`}>
                            {item.subCategoryName}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

export default MobileNavMenu;
