import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const MobileSearch = () => {
  const [categoryData, setcategoryData] = useState(null);
  const [selectedCat, setselectedCat] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [fromPrice, setFromPrice] = useState(0);
  const [suggetions, setsuggetions] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [toPrice, setToPrice] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setloading] = useState(false);

  const onChange = (id) => {
    setselectedCat(id);
  };

  return (
    <div className="offcanvas-mobile-search-area">
      {/* <form action="#"> */}
      <form action="">
        <div className="input-group">
          <div className="SearchBar">
            <input
              type="text"
              className="form-control"
              placeholder="Search for products"
              onChange={(e) => setSearchText(e.target.value)}
              value={selectedItem}
              style={{
                borderTopLeftRadius: 18,
                borderBottomLeftRadius: 18,
                width: "160px",
                backgroundColor: "white",
                color: "grey",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                padding: "10px",
                borderRightWidth: 0,
                boxShadow: "none",
              }}
            />
            {searchText !== "" && (
              <ul>
                {suggetions &&
                  suggetions.length > 0 &&
                  suggetions.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setselectedItem(item.productName);
                          setsuggetions(null);
                        }}
                      >
                        <div className="row">
                          <div className="col-xl-9" style={{ margin: 0 }}>
                            {item.productName.slice(0, 35)}....
                          </div>
                          <div
                            className="col-xl-3"
                            style={{ margin: 0, padding: 0 }}
                          >
                            {item.productPrice == 0 ? (
                              <>Ask for price</>
                            ) : (
                              <> ₹{item.productPrice}</>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
          <select
            className="form-control"
            aria-label="Default select example"
            style={{
              maxWidth: "30%",
              color: "grey",
              border: 0,
              boxShadow: "none",
            }}
            onChange={(e) => onChange(e.target.value)}
          >
            <option>Category</option>
            {categoryData &&
              categoryData.map((item) => {
                return (
                  <option value={item.mainCategoryId} key={item.mainCategoryId}>
                    {item.productCategoryName}
                  </option>
                );
              })}
          </select>

          {searchText === "" && fromPrice === 0 && toPrice === 0 ? (
            <button
              className="btn btn-warning"
              style={{
                borderTopRightRadius: 18,
                borderBottomRightRadius: 18,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                width: "13%",
              }}
              disabled
            >
              <i className="fas fa-search"></i>
            </button>
          ) : (
            <button
              className="btn btn-warning"
              style={{
                borderTopRightRadius: 18,
                borderBottomRightRadius: 18,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                width: "13%",
              }}
            >
              {loading ? (
                <>
                  <div
                    className="spinner-border text-light spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  <i className="fas fa-search"></i>
                </>
              )}
            </button>
          )}
        </div>
      </form>
      {/* </form> */}
    </div>
  );
};

export default MobileSearch;
