import {
  FETCH_FILTER_COLORS,
  FETCH_FILTER_GENDER,
  FETCH_FILTER_PRICE,
  FETCH_FILTER_SIZES,
  FETCH_FILTER_SUB_CATEGORIES,
} from "../actions/filterData";

const initState = {
  filterData: {
    price: { minPrice: 0, maxPrice: 100000 },
    colors: [],
    sizes: [],
    gender: [],
    subCategories: [],
  },
};

const filterData = (state = initState, action) => {
  if (action.type === FETCH_FILTER_PRICE) {
    return {
      ...state,
      price: {
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
      },
    };
  } else if (action.type === FETCH_FILTER_COLORS) {
    return {
      ...state,
      colors: action.payload,
    };
  } else if (action.type === FETCH_FILTER_SIZES) {
    return {
      ...state,
      sizes: action.payload,
    };
  } else if (action.type === FETCH_FILTER_GENDER) {
    return {
      ...state,
      gender: action.payload,
    };
  } else if (action.type === FETCH_FILTER_SUB_CATEGORIES) {
    return {
      ...state,
      subCategories: action.payload,
    };
  }

  return state;
};

export default filterData;
