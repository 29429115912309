import { SEARCHED_DATA } from "../actions/searchedData";

const initState = {
  searchedData: [],
};

const searchedData = (state = initState, action) => {
  if (action.type === SEARCHED_DATA) {
    return {
      ...state,
      searchedData: action.payload,
    };
  }

  return state;
};

export default searchedData;
