import { FETCH_USER_SUCCESS, USER_LOGOUT } from "../actions/sessionAction";

const initState = {
  user: [],
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case USER_LOGOUT:
      return {
        user: initState,
      };
  }

  return state;
};

export default userReducer;
