export const FETCH_FILTER_DATA = "FETCH_FILTER_DATA";

const fetchFilterPriceSuccess = (price) => ({
  type: FETCH_FILTER_DATA,
  payload: price,
});

// fetch products
export const fetchFilteredData = (price) => {
  return (dispatch) => {
    dispatch(fetchFilterPriceSuccess(price));
  };
};
