import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${
        headerPositionClass ? headerPositionClass : ""
      }`}
    >
      <HeaderTop borderStyle={borderStyle} />
      <div
        className={`sticky-bar header-res-padding clearfix ${
          scroll > headerTop ? "stick" : ""
        }`}
        // style={{ marginRight: "15px" }}
      >
        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-6 col-4 d-lg-none">
            {/* header logo */}
            <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" />
          </div>
          <div className="col-xl-12 col-lg-8 d-none d-lg-block">
            {/* Nav menu */}
            <NavMenu />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 col-8 d-lg-none">
            {/* Icon group */}
            <IconGroup />
          </div>
        </div>
        <div
          className={layout === "container-fluid" ? layout : "container"}
        ></div>

        {/* mobile menu */}
        <MobileMenu />
      </div>
      
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
