import { SET_IMAGE_DATA } from "../actions/imageData";

const initState = {
  imageData: [],
};
const imagesData = (state = initState, action) => {
  if (action.type === SET_IMAGE_DATA) {
    return {
      imagesData: action.payload,
    };
  }

  return state;
};

export default imagesData;
