import PropTypes from "prop-types";
import React from "react";

const SectionTitlePrivacyText = ({ spaceTopClass, spaceBottomClass }) => {
  return (
    <div
      className={`welcome-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="welcome-content">
          <p>
            <strong>1. General</strong>
          </p>
          <p>
            A2R Creative, having its registered office at 2nd Floor, 11, Astitva
            Building, Railway Parallel Rd, Nehru Nagar, Seshadripuram,
            Bengaluru, Karnataka 560020, is managing and operating the website{" "}
            <a data-fr-linked="true" href="//www.creativeswag.com">
              www.creativeswag.com
            </a>
            , through which it inter alia [p1] facilitates the sale and purchase
            of a diverse range of corporate promotional merchandise products
            listed on the Platforms from time to time and other products
            (&ldquo;Products&rdquo;) to the users of the Platforms
            (&ldquo;User&rdquo; or &ldquo;Users&rdquo; or &ldquo;you&rdquo; or
            &ldquo;your&rdquo;)
          </p>
          <p>
            <br />
          </p>
          <p>
            This privacy statement (&quot;Privacy Statement&quot;), together
            with the Terms, defines how you may use the Platforms. It outlines
            our policies and practices for gathering, using, disclosing,
            processing, transferring, and storing the information you give to
            us. You consent to the collection, storage, possession, dealing,
            handling, sharing, disclosure, or transfer of your information in
            accordance with the terms of this Privacy Policy by using, browsing,
            accessing, or making purchases from the Platforms. You also agree to
            be bound by the terms of this Privacy Policy. Except as stated in
            this Privacy Policy, we will not use the User&apos;s information in
            any other way. If a term is capitalized and is not otherwise
            defined, it will be understood to imply what is implied by the
            Terms.
          </p>
          <p>
            <br />
          </p>
          <p>
            This privacy statement outlines the categories of information that
            we may collect from Users, including the sensitive personal data or
            information (defined below), its nature, intended uses, collection
            methods, recipients, and other relevant information. You have the
            right to revoke your permission at any time for the gathering and
            use of your data, including any Personal Information (as defined
            below) or Sensitive Personal Data or Information. Please be aware,
            though, that in the event that you withdraw your consent, we might
            no longer be able to offer you the associated service. It is hereby
            clarified that your decision to withdraw your consent will not
            affect the processing of Personal Information based on your previous
            consent prior to the withdrawal.
          </p>
          <p>
            <br />
          </p>
          <p>
            This document is an electronic record and is controlled by the
            Information Technology Act, 2000, and any rules promulgated
            thereunder, as well as the provisions of numerous statutes dealing
            with electronic records that have been modified by the Information
            Technology Act, 2000. There are no physical or digital signatures
            necessary for this electronic record because it is produced by a
            computer system.
          </p>
          <p>
            <br />
          </p>
          <p>
            Please take time to go through our privacy statement. We urge you
            not to use or access the Platforms if you disagree with any clauses
            in the Terms or this Privacy Policy.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>2. COPYRIGHTS</strong>
          </p>
          <p>
            Products in this catalog may be patented, have patents pending, or
            be subject to various copyright and/or property rights of A2R
            Creative and/or its owners and principals. These products may not be
            reproduced in any way (in whole or in part) without express written
            permission from A2R Creative.
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>3. Type of Information Collected</strong>
          </p>
          <p>
            You pledge to submit information that is accurate, truthful, and
            up-to-date. You can contact us at{" "}
            <a data-fr-linked="true" href="mailto:support@a2rcreative.in">
              support@a2rcreative.in
            </a>{" "}
            to request access to, modify, change, or have all of your
            information deleted.
          </p>
          <p>
            Every time you visit, access, use, or explore the Platforms, you
            could be prompted to enter your information. We may use and disclose
            information in accordance with the terms of this privacy statement.
            In order to deliver and enhance our products, services, content, and
            advertising, we may also combine it with other information.
          </p>
          <p>
            During your visit to the Site, we may gather information that cannot
            be used to identify you personally. We may use third-party software
            to gather this data, and we retain the right, at our sole
            discretion, to modify our data collecting strategies, providers,
            and/or software at any time. It may be possible for you to change
            the settings on the electronic device you use to access the Site
            (such as a desktop or laptop computer, phone, or tablet) (your
            &quot;Device&quot;) if you do not want such non-personally
            identifiable information collected. We cannot, however, promise that
            these options can be modified or will be put into effect, and by
            using the Site, you accept all risks.
          </p>
          <p>
            We could also get personal data from you or from other people. We
            refer to information that may be used to identify a specific person
            as &quot;Personal Information&quot;; this individual might be you or
            someone else, such as the receiver of a Swag Pack, Bulk Swag Order,
            Preset Pack, or any other purchase from the A2R Creative website
            (collectively, the &quot;Order&quot;). Device IDs, IP addresses, and
            &quot;cookies&quot; (&quot;Cookie Data&quot;) are examples of
            Personal Information. If this kind of data is gathered, it is done
            so using our &quot;Tracking Technologies,&quot; which are outlined
            below. When you sign up for a service or buy anything for yourself
            or the receiver of the Order, you provide us other personal
            information.You acknowledge that the Site is not secure and that
            data transmissions via email, network uploads, and on site forms are
            fundamentally unsafe by using the Site and giving us any
            information. Although we won&apos;t knowingly divulge personal
            information, we have no control over outside intervention and are
            not responsible for it. You agree not to send any information via
            email or the Website that you or others would not want made public.
          </p>
          <p>
            The Platforms are not intended for use by children. We never
            intentionally gather personal data from users under the age of 18
            (Eighteen). If it is discovered that we have obtained the personal
            information of a person under the age of 18 (Eighteen), we shall
            take reasonable efforts to have it removed. If a parent or legal
            guardian learns that their kid has shared personal information on
            the Platforms without their permission, he or she must get in touch
            with us to request the deletion of such information. In that event,
            where in contravention to the Privacy Policy and Terms, a person
            below the age of 18 (Eighteen) uses the services, we shall not be
            held liable or responsible for any damage or injury suffered by such
            person.
          </p>
          <p>
            Your name, mailing address, phone number, email address, and contact
            preferences may all be collected by us, along with any other use and
            interaction information you may have with us or our affiliates. We
            may gather data that, when coupled with other pieces of information
            we have on hand, fairly enables us to identify you (hence,
            &quot;Personal Information&quot;). Personal information includes
            things like your full name, mobile or home phone number, mailing
            address, email, gender, and birth date. Any media, not only pictures
            or papers, can include personal information.
          </p>
          <p><strong>4. Use of Information Collected</strong></p>
          <p>
            For the following purposes, we may gather, utilise, or process your
            information, including sensitive personal data or information:
          </p>
          <p>
            To keep you up to speed on all of our products, services, special
            deals, changes, and forthcoming events. This includes telling you
            about order confirmations, bills, technical problems, and security
            alerts.
          </p>
          <p>
            For setting up your registered account on the Platforms and granting
            you access to it.
          </p>
          <p>
            In order to enhance and customise your experience, we constantly
            create, distribute, process, and improve our products, services, and
            content.
          </p>
          <p>
            For internal audits, data analysis, and research projects to enhance
            our services, products, and customer communications.
          </p>
          <p>
            Must abide by all legal and regulatory requirements, as well as
            requests made by any governmental or judicial body.
          </p>
          <p>
            To address any inquiry, argument, grievance, or complaint you may
            have regarding your usage of the platforms.
          </p>
          <p>
            Identifying and keeping an eye on any fraudulent or improper conduct
            on the Platforms.
          </p>
          <p><strong>5. Disclosure of Information Collected</strong></p>
          <p>
            &nbsp;We could occasionally be compelled to provide our trusted
            third-party service providers who help us make it easier to offer
            services and make Product purchases on the Platforms access to the
            information we&apos;ve gathered from you. To complete transactions
            on the Platforms, for instance, we could divulge your information to
            third-party payment gateway providers. You agree to any such sharing
            of your information to third-party service providers by using the
            Platforms. In order to protect this information, we make sure that
            these third-party service providers are subject to reasonable
            confidentiality obligations and/or utilise, maintain, and abide by
            widely recognised industry and security standards.
          </p>
          <p>
            In the event that we go through a business restructuring, asset
            sale, division, or transfer of all or a portion of our business, we
            may also disclose or transfer your information to any third party.
            We will make sure that any third parties to whom we transfer or sell
            our assets are required to handle your Personal Information with
            suitable confidentiality and security safeguards, at the very least
            comparable to those outlined in this Privacy Policy. If the new
            entity&apos;s intended processing of your information differs
            considerably from that described in this Privacy Policy, you will
            have the option to refuse any such transfer.
          </p>
          <p>
            We may also release your information if required to do so by law or
            court order, or if we determine in our sole discretion that doing so
            is necessary to defend our rights or the rights of other Users, to
            stop harm to persons or property, to fight fraud, or to reduce the
            risk of credit risk.
          </p>
          <p>
            Your credit/debit card number or bank account information, which are
            generally referred to as &quot;Financial Information,&quot; may need
            to be collected from you by a third-party payment gateway provider.
            All financial information that these third-party payment gateway
            providers obtain from you will only be applied to the invoicing and
            payment procedures.Your Financial Information is handled using
            encrypted, secure digital platforms used by authorised payment
            gateways, in accordance with the generally accepted standards for
            technology. We won&apos;t be involved in the authentication
            procedure used to verify the Financial Information, thus we
            won&apos;t be held responsible for it. Only you will be responsible
            for carrying out this step. In the event that third-party payment
            gateway providers violate any terms, representations, or guarantees
            they have made, we will not be held liable or accountable.
            Additionally, we are under no obligation to arbitrate or settle any
            disagreements you may have with such third-party payment service
            providers.
          </p>
          <p>
            You are strongly advised to use reasonable caution when providing
            Personal Information or Financial Information while using the
            services given that the Internet is prone to security breaches.
            While we make every effort to ensure that your information,
            including Personal Information, Financial Information, and Sensitive
            Personal Information or Data is duly protected by undertaking
            security measures prescribed under applicable laws.
          </p>
          <p><strong>6. Protection of personal information:</strong></p>
          <p>
            We place a high priority on the security of your personal
            information. To safeguard the Personal Information under its control
            from unauthorised access, improper use or disclosure, unauthorised
            modification, unlawful destruction, or accidental loss, we have put
            in place the security policies, rules, and technical safeguards
            required by applicable law. These safeguards include firewalls,
            transport layer security, and other physical and electronic security
            measures. Your information is safeguarded by our security mechanisms
            when you submit it on the Platforms. Please be aware that we adhere
            to all applicable laws, rules, and regulations by using
            internationally recognised standards.
          </p>
          <p>
            Your information is kept on protected networks and is only accessed
            by a small group of people with access privileges or other
            requirements for the information for the purposes specified in this
            privacy policy. These authorised individuals have a duty to maintain
            the privacy of such information.
          </p>
          <p>
            You understand and acknowledge that there is no such thing as
            perfect security and that we cannot guarantee that there won&apos;t
            be any unintended disclosures of any information or potential
            security breaches, despite our best efforts to transmit and store
            all the information you provide in a secure operating environment
            that is not accessible to the public. You acknowledge and agree that
            we are not liable for acts of government, computer hacking,
            unauthorised access to computer data and storage devices, computer
            crashes, technical difficulties, breaches of security and
            encryption, etc., nor for any actions of third parties who obtain
            your personal information.
          </p>
          <p><strong>7. Cookies policy:</strong></p>
          <p>
            In order to manage the Site, track users&apos; online activities,
            analyse trends, and collect demographic data about our user base as
            a whole, we employ technologies like cookies, beacons, tags, and
            scripts. We could get information about how these firms, both
            individually and collectively, employ these technology. We utilise
            cookie data to remember users&apos; settings (such language
            preference) and for authentication in addition to the various uses
            of personal information that are indicated in this document. The
            usage of cookies can be managed by users at the browser level. You
            can still use our site if you reject cookies, but you might have
            fewer options for using certain of its features or sections.We
            automatically receive the uniform resource locator (URL) of the
            website from which you visit, access, or browse the Platforms,
            information about the website you visit after leaving the Platforms,
            the internet protocol (IP) address of each User&apos;s computer
            operating system, the type of web browser each User is using, email
            usage patterns, and the name of the User&apos;s internet service
            provider when you visit, access, or browse the Platforms. This data
            is only used to analyse general user patterns and assist us in
            bettering our services. Please take note that, except as required by
            law or to supply or facilitate the User with the services, the
            connection between the User&apos;s IP address and their personally
            identifiable information is not disclosed to third parties without
            the User&apos;s consent. Despite the foregoing, the User
            acknowledges that we reserve the right to share some of the overall
            results, including the personal information provided by Users in an
            anonymous, aggregate form and not the specific data, with
            advertisers, sponsors, investors, strategic partners, and other
            parties in order to support business expansion. The settings of the
            web browser the User uses to access the Platforms determine how much
            data is transferred to us. If the User wants to see what information
            is supplied to us, the User can look at the browser that was used.
          </p>
          <p>
            The Platforms save particular data via temporary cookies. We
            don&apos;t keep any personal data in the cookies. Information
            gathered by us through any method is solely our property and may be
            used by us and third-party service providers for technical
            administration of the Platforms, user administration, research,
            development, and other purposes. This information does not
            personally identify the User as an individual (such as the usage
            patterns mentioned above).
          </p>
          <p>
            You are aware that you may configure your web browsers to disable or
            erase cookies. It may impede, degrade, or restrict access to some
            parts of the Platforms if you decide to disable cookies on your
            computer or mobile device.
          </p>
          <p>
            You acknowledge and accept that we have no control over any external
            websites that are linked to or shown as search results on the
            Platforms. These other websites may download cookies or other files
            to the user&apos;s computer, gather data, or ask for personal
            information from the user; we have no control over these third-party
            websites and are not responsible or accountable for them. We do not
            guarantee the accuracy, integrity, or quality of the information,
            data, text, software, sound, photographs, graphics, videos,
            messages, or other materials available on such websites and we do
            not make any representations regarding the privacy practises or
            policies of such third parties or the terms of use of such websites.
            No endorsement by us of such websites, their provider, or the
            content on such websites is inferred by our inclusion or omission.
          </p>
          <p>
            We could let other businesses or organisations to show you adverts.
            These organisations or businesses include third-party ad servers,
            advertising agencies, ad technology suppliers, and research
            companies. We could show you certain ads that correspond to a
            particular broad profile. We don&apos;t use your personal
            information to send you targeted ads. We may permit authorised third
            parties to install or recognise a special cookie on the User&apos;s
            browser in the process of providing adverts or improving the
            services to its Users.
          </p>
          <p>
            For the administration of services, research and development,
            training, business intelligence, business growth, or user
            administration, we may maintain records of phone calls made to and
            received from Users. When needed by law or in order to offer or
            facilitate the services to the User, we may disclose such telephone
            records with third parties.
          </p>
          <p>
            You agree that we may post all testimonials and reviews you provide
            on the platforms on our products or services. You consent to our
            right to alter any testimonials and reviews you provide, and to
            reproduce or post any changed or paraphrased testimonials and
            reviews on the Platforms. Contact us at{" "}
            <a data-fr-linked="true" href="mailto:support@a2rcreativeswag.com">
              support@a2rcreativeswag.com
            </a>{" "}
            if the User has any questions or concerns about the replication or
            publication of any testimonial or review that you have submitted.
          </p>
          <p><strong>8. Opt-Out Policy:</strong></p>
          <p>
            The third-party service providers whose information we may share
            with them do not have the right to advertise their products to you,
            send you marketing emails, or otherwise interact with you for
            promotional purposes. We provide you the option to stop receiving
            promotional, marketing-related, or non-essential communication from
            us or our partners.
          </p>
          <p>
            You may use the &quot;unsubscribe&quot; link or the instructions in
            each email message to remove your contact information from all of
            our mailing lists and newsletters. You may also email us at{" "}
            <a data-fr-linked="true" href="mailto:support@a2rcreativeswag.com">
              support@a2rcreativeswag.com
            </a>
            . Depending on the accessibility of contact information, we retain
            the right to restrict membership. Prior to any measures being taken,
            an email will be sent to all Users.
          </p>
          <p><strong>9. Retention of Information:</strong></p>
          <p>
            After receiving your request to have your personal information
            deleted, we will only keep a record of it and use it as needed to
            meet our legal requirements, settle disputes, and uphold our
            agreements. We may disclose Personal Information in the following
            circumstances:
          </p>
          <p>
            If we determine that a violation of the Terms has taken place;&nbsp;
          </p>
          <p>
            If we believe that such disclosure is required to establish
            liability, bring a claim, or otherwise protect our rights or the
            rights of another user of the Site;&nbsp;
          </p>
          <p>
            To comply with legal process or as otherwise required by law;
            and&nbsp;
          </p>
          <p>
            To help us protect against or investigate fraud. Any data breach or
            unauthorised access to user content or other information on or
            submitted through the site will not subject us to any liability in
            any way. Any information you provide on the website or in
            conjunction with your use of the website is not considered
            confidential and will not be treated as such, unless otherwise
            required by law.
          </p>
          <p>
            You acknowledge that we may use the information we get from you for
            some or all of the purposes listed above. Unless a longer retention
            time is mandated or authorised by the relevant law, we will only
            keep your information and any data as long as it takes to accomplish
            the objectives listed in this privacy policy.
          </p>
          <p><strong>10. Changes to policy:</strong></p>
          <p>
            We may amend and update this policy from time to time. Any changes
            in the future will be posted on this page. The date of the latest
            update to this privacy policy is set out at the top of this policy.
          </p>
          <p><strong>11. Grievance Redressed</strong></p>
          <p>
            In case, you have any questions, grievances, or complaints about
            this Privacy Policy, or the Platforms, you may contact our grievance
            officer on the below-mentioned details:
          </p>
          <p>Name: Vidya</p>
          <p>Designation: Project Manager</p>
          <p>
            Email Address:{" "}
            <a data-fr-linked="true" href="mailto:finance@a2rcreativeswag.com">
              finance@a2rcreativeswag.com
            </a>
          </p>
          <p><strong>12. Governing Law</strong></p>
          <p>
            The terms of this Privacy Policy shall be governed and construed in
            accordance with the laws of India. Any dispute regarding or arising
            out of this Privacy Policy shall be subject to the exclusive
            jurisdiction of the courts in Bengaluru, Karnataka.
          </p>
          <p><strong>13. Severability:</strong></p>
          <p>
            Each section of this privacy policy must, if feasible, be read in a
            way that makes it compliant with all legal requirements. However, in
            the event that any provision is found to be illegal or invalid, such
            provision shall only be ineffective to the extent of such illegality
            or invalidity, and the balance of such provision or other parts of
            this privacy policy shall continue in full force and effect.
          </p>
        </div>
      </div>
    </div>
  );
};

SectionTitlePrivacyText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default SectionTitlePrivacyText;
