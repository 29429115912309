export const FETCH_FILTER_PRICE = "FETCH_FILTER_PRICE";
export const FETCH_FILTER_COLORS = "FETCH_FILTER_COLORS";
export const FETCH_FILTER_SIZES = "FETCH_FILTER_SIZES";
export const FETCH_FILTER_GENDER = "FETCH_FILTER_GENDER";
export const FETCH_FILTER_SUB_CATEGORIES = "FETCH_FILTER_SUB_CATEGORIES";

const fetchFilterPriceSuccess = (price) => ({
  type: FETCH_FILTER_PRICE,
  payload: price,
});
const fetchFilterColorsSuccess = (colors) => ({
  type: FETCH_FILTER_COLORS,
  payload: colors,
});
const fetchFilterSizesSuccess = (sizes) => ({
  type: FETCH_FILTER_SIZES,
  payload: sizes,
});
const fetchFilterGenderSuccess = (Gender) => ({
  type: FETCH_FILTER_GENDER,
  payload: Gender,
});
const fetchFilterSubCategoriesSuccess = (Gender) => ({
  type: FETCH_FILTER_SUB_CATEGORIES,
  payload: Gender,
});

// fetch products
export const fetchFilterPrice = (price) => {
  return (dispatch) => {
    dispatch(fetchFilterPriceSuccess(price));
  };
};
export const fetchFilterColors = (colors) => {
  return (dispatch) => {
    dispatch(fetchFilterColorsSuccess(colors));
  };
};
export const fetchFilterSizes = (sizes) => {
  return (dispatch) => {
    dispatch(fetchFilterSizesSuccess(sizes));
  };
};
export const fetchFilterGender = (gender) => {
  return (dispatch) => {
    dispatch(fetchFilterGenderSuccess(gender));
  };
};
export const fetchFilterSubCategories = (subCategory) => {
  return (dispatch) => {
    dispatch(fetchFilterSubCategoriesSuccess(subCategory));
  };
};
