import axios from "axios";

const BASE_URL = "https://api.creativeswag.in:3000/api/v1";
// const BASE_URL = "http://localhost:3000/api/v1";
// 159.89.168.134:3000
// 54.212.9.139:3000
// https://creativeswagapi.herokuapp.com/api/v1

const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user).currentUser;
const TOKEN = currentUser?.accessToken;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  header: { token: `Bearer ${TOKEN}` },
});
