import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
          <img alt="" src={process.env.PUBLIC_URL + footerLogo} />
        </Link>
      </div>
      <p className="reserved">
        &copy; {new Date().getFullYear()}{" "}
        <a
          href="http://www.creativeswag.in/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Creative Swag <span />
        </a>
        All Rights Reserved
      </p>
    </>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
