import PropTypes from "prop-types";
import React from "react";

const SectionTitleTermsAndConditionText = ({
  spaceTopClass,
  spaceBottomClass,
}) => {
  return (
    <div
      className={`welcome-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className="container">
        <div className="welcome-content">
          <p>
            <h2>Terms and conditions</h2>
          </p>
          <p>
            <strong>Use of the website</strong>
          </p>
          <p>
            1. You agree not to copy, reproduce, modify, create derivative works
            from, distribute or publicly display any content (except for your
            own information) from the service without our prior written
            permission.
          </p>
          <br />
          <p>
            2. You acknowledge that all present and future copyright and other
            intellectual property rights subsisting in, or used in connection
            with, the website or any part of it (the "rights"), including the
            manner in which the website is presented or appears and all
            information and documentation relating to it is our property (or
            that of our licensors), and nothing in these terms shall be taken to
            transfer any of the rights to you.
          </p>
          <br />
          <br />
          <p>
            3. Solely for the purposes of accessing the website and placing
            orders we hereby grant to you, for the period during which the
            website is made available to you, a non-exclusive, non-transferable,
            licence to use the rights.
          </p>
          <br />
          <p>
            4. You may not link to the website, other than to the url:
            www.creativeswag.in , without our prior written consent.
          </p>
          <br />

          <p>
            <strong>The order process</strong>
          </p>
          <p>
            1. You may select items from our range of products, details of which
            will be added to the "shopping bag" by clicking on the 'add to
            shopping cart' button. By clicking on the 'proceed to checkout'
            button, you initiate the checkout process for the products added to
            the shopping cart. By clicking on the 'pay now' button you submit an
            offer to buy the products in the shopping cart and agree to pay for
            those items. Before placing an order you can view and amend your
            order details at any time by clicking on the 'shopping cart &
            checkout' button.
          </p>
          <br />
          <p>
            2. When you place your order to purchase products from the website,
            we will automatically send you an email confirming receipt of your
            order and containing the details of your order (the "confirmation
            email"). We recommend that you print out the confirmation email and
            keep it for your own records. The confirmation email does not
            constitute our acceptance of your order; it merely records the fact
            that we have received the order.
          </p>
          <br />
          <p>
            3. We will send you a second email when we dispatch your products
            which shall constitute our acceptance of your order (the "acceptance
            email"), at which point a binding contract of sale will be concluded
            between you and us.
          </p>
          <br />
          <p>
            4. Any products on the same order which we have not confirmed in an
            acceptance email do not form part of that contract. If for any
            reason the products ordered by you are not available at the time you
            place your order, we will notify you of this by email. Our
            acceptance of your order in the acceptance email is limited to the
            products that are available - no contract of sale will be concluded
            in respect of products that are not available. We will retain the
            title in the products until full payment has been received by us.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Payment</strong>
          </p>
          <p>
            1. Payment for your products must be made via credit or debit card,
            internet banking, or other means as specified on the payment page.
          </p>
          <br />
          <p>
            2. The full payment price, including delivery charges and any
            additional charges for services you may have ordered, will be
            debited from your card or account before the order is accepted by
            us.
          </p>
          <br />
          <p>
            3. You confirm that the credit or debit card or internet banking
            account that is being used is yours. All credit or debit card
            holders are subject to validation checks and authorisation by the
            card issuer. If the issuer of your card refuses to authorise payment
            we will not accept your order and we will not be responsible for any
            delay or non-delivery and we are not obliged to inform you of the
            reason for the refusal.
          </p>
          <br />
          <p>
            4. When you visit the website or send emails to us, you are
            communicating with us electronically. We will communicate with you
            by email or by posting notices on the website. For contractual
            purposes, you consent to receive communications from us
            electronically and you agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing. This condition does not affect your
            statutory rights.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Feedback and complaint</strong>
          </p>
          <p>
            We welcome any comments about our service, so that we can improve
            what we are doing. our aim is to deal with any problems quickly and
            fairly. if you have reason to complain about our service, please
            call or email our customer services team stating clearly that you
            are making a complaint. our aim is to respond to your complaint
            within seven (7) working days, either resolving the complaint, or
            with a timescale for resolution.
          </p>

          <p>
            <br />
          </p>
          <p>
            <strong>Governing law and jurisdiction</strong>
          </p>
          <p>
            Your use of the website, any purchase by you on the website of any
            products and these conditions will be governed by and construed in
            accordance with the stipulated laws. you agree, as we do, to submit
            to the non-exclusive jurisdiction of Bangalore.
          </p>
          
        </div>
      </div>
    </div>
  );
};

SectionTitleTermsAndConditionText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default SectionTitleTermsAndConditionText;
