import { SET_SELECTED_IMAGE } from "../actions/selectedImage";

const initState = {
  selectedImage: [],
};
const selectedImageReducer = (state = initState, action) => {
  if (action.type === SET_SELECTED_IMAGE) {
    return {
      selectedImage: action.payload,
    };
  }

  return state;
};

export default selectedImageReducer;
