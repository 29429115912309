import React, { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { ThroughProvider } from "react-through";
import PrivacyPolicy from "./pages/other/PrivacyPolicy";
import ReturnRefundPolicy from "./pages/other/RetrunRefundPolicy";
import TermsAndConditions from "./pages/other/TermsAndConditions";
import shippingpolicy from "./pages/other/ShippingPolicy";
const HomeElectronicsThree = lazy(() =>
  import("./pages/home/HomeElectronicsThree")
);

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// Home Page
const LandingPage = lazy(() => import("./pages/landing-page/LandingPage"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const BlogDetails = lazy(() => import("./pages/blog/BlogDetailsStandard"));
const Blog1 = lazy(() => import("./wrappers/blog/Blog1"));
const Blog2 = lazy(() => import("./wrappers/blog/Blog2"));
const Blog3 = lazy(() => import("./wrappers/blog/Blog3"));
const Blog4 = lazy(() => import("./wrappers/blog/Blog4"));
const Blog5 = lazy(() => import("./wrappers/blog/Blog5"));
const Blog7 = lazy(() => import("./wrappers/blog/Blog7"));
const Blog8 = lazy(() => import("./wrappers/blog/Blog8"));
const Blog12 = lazy(() => import("./wrappers/blog/Blog12"));
const Blog13 = lazy(() => import("./wrappers/blog/Blog13"));
const Blog14 = lazy(() => import("./wrappers/blog/Blog14"));

const App = () => {
  return (
    <ToastProvider placement="bottom-center">
      <ThroughProvider>
        <Router>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <ScrollToTop>
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeElectronicsThree}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/landing-page"}
                  component={LandingPage}
                />
                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop/:pathname"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop/searchResults=:data"}
                  component={ShopGridStandard}
                />
                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog"}
                  component={BlogStandard}
                />{" "}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details1"}
                  component={Blog1}
                />{" "}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details2"}
                  component={Blog2}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details3"}
                  component={Blog3}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details4"}
                  component={Blog4}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details5"}
                  component={Blog5}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details7"}
                  component={Blog7}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details8"}
                  component={Blog8}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details12"}
                  component={Blog12}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details13"}
                  component={Blog13}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details14"}
                  component={Blog14}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard/:id"}
                  component={BlogDetails}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/privacypolicy"}
                  component={PrivacyPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/returnandrefundpolicy"}
                  component={ReturnRefundPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/termsandconditions"}
                  component={TermsAndConditions}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shippingpolicy"}
                  component={shippingpolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/enquiry"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route exact component={NotFound} />
              </Switch>
            </ScrollToTop>
          </Suspense>
        </Router>
      </ThroughProvider>
    </ToastProvider>
  );
};

export default App;
