import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { publicRequest } from "../../axios/axios";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  const [categoryList, setcategoryList] = useState(null);
  const getGategoryList = async () => {
    await publicRequest
      .post("/mainCategory/categoryList", { isActive: 1 })
      .then((response) => {
        console.log(response.data.data)
        const sortedCategory = response.data.data.sort((a, b) => a.serial - b.serial)
        console.log(sortedCategory)
        setcategoryList(sortedCategory);
      });
  };
  function compareName(a, b) {
    // converting to uppercase to have case-insensitive comparison
    const serial1 = a.serial;
    const serial2 = b.serial;

    let comparison = 0;

    if (serial1 > serial2) {
      comparison = 1;
    } else if (serial1 < serial2) {
      comparison = -1;
    }
    return comparison;
  }
  useEffect(() => {
    getGategoryList();
    return () => {
      setcategoryList([]);
    };
  }, []);

  categoryList && categoryList.sort(compareName);

  return (
    <div
      className={` ${sidebarMenu
        ? "sidebar-menu"
        : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
        } `}
    >
      <nav>
        <ul>
          {categoryList &&
            categoryList.length > 0 &&
            categoryList.map((item, index) => {
              item.subCategory && item.subCategory.sort(compareName)
              return (
                <li key={index}>
                  {item.isActive && (
                    <Link to={`/shop/category=${item.mainCategoryId}`}>
                      {item.productCategoryName}
                      {item.subCategory.length > 0 && (

                        <>
                          {/* {item.subCategory && item.subCategory.sort(compareName)} */}
                          {sidebarMenu ? (
                            <span>
                              <i className="fa fa-angle-right"></i>
                            </span>
                          ) : (
                            <i className="fa fa-angle-down" />
                          )}
                        </>
                      )}
                    </Link>
                  )}
                  {item.subCategory && item.subCategory.length > 0 && (
                    <ul className="mega-menu">
                      {item.subCategory &&
                        item.subCategory.length > 0 &&
                        item.subCategory.map((subitem, index) => {
                          return (
                            <li key={subitem.subCategoryId}>
                              {subitem.isActive && (
                                <ul>
                                  <li className="mega-menu-title">
                                    <Link
                                      to={`/shop/sub-category=${subitem.subCategoryId}`}
                                    >
                                      {subitem.subCategoryName}
                                    </Link>
                                  </li>
                                </ul>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default NavMenu;
