import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { BsPatchQuestion } from "react-icons/bs";
import { VscAccount } from "react-icons/vsc";
import { FiShoppingBag } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { userLogout } from "../../redux/actions/sessionAction";
import { deleteAllFromCart } from "../../redux/actions/cartActions";
import { deleteAllFromInquiry } from "../../redux/actions/inquiryActions";

const IconGroup = ({ iconWhiteClass }) => {
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  const user = useSelector((state) => state.userData.user);
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cartData);
  const inquiryItems = useSelector((state) => state.inquiryData);

  const handleLogout = () => {
    dispatch(userLogout());
    localStorage.removeItem("cartId");
    localStorage.removeItem("enquiryId");
    dispatch(deleteAllFromCart());
    dispatch(deleteAllFromInquiry());
    window.location.reload();
  };
  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      <div className="same-style header-search d-none d-lg-none">
        <button className="search-active" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>
      <div className="same-style account-setting d-none d-lg-block">
        {user.user && user.user.firstName ? (
          <span>{user.user.firstName}</span>
        ) : (
          <span>Login Now</span>
        )}
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
        >
          <VscAccount />
        </button>

        <div className="account-dropdown">
          <ul>
            {user.user && user.user.firstName ? (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>
                    my account
                  </Link>
                </li>{" "}
                <li onClick={() => handleLogout()}>
                  <a>LogOut</a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>
                    Login
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login-register"}>
                    Register
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className="same-style header-wishlist d-lg-block">
        <Link to={process.env.PUBLIC_URL + "/enquiry"}>
          <BsPatchQuestion />
          <span className="count-style">
            {inquiryItems && inquiryItems.length ? inquiryItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <Link to={"/cart"}>
          <FiShoppingBag />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
        {/* menu cart */}
        {/* <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        /> */}
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  userdata: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps)(IconGroup);
