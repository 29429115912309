import PropTypes from "prop-types";
import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { publicRequest } from "../../../axios/axios";
import validator from "validator";

const CustomForm = ({ spaceTopClass, subscribeBtnClass }) => {
  const [email, setEmail] = useState(null);
  const [message, setmessage] = useState("");
  const [status, setStatus] = useState(null);
  const submit = () => {
    subscribe();
    setEmail("");
  };
  const subscribe = () => {
    if (validator.isEmail(email)) {
      publicRequest.post("/subscribe", { emailId: email }).then((res) => {
        setStatus(res.data);
        // console.log(status);
      });
    } else {
      setmessage("Please Enter A Valid Email!");
    }
  };
  return (
    <div className={`subscribe-form-3 ${spaceTopClass ? spaceTopClass : ""}`}>
      <div className="mc-form">
        <div>
          <input
            className="email"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Your Email Address"
            required
          />
        </div>
        {status?.status == 200 ? (
          <div
            style={{ color: "#2ecc71", fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: status?.msg }}
          />
        ) : message !== "" ? (
          <div
            style={{ color: "red", fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ) : (
          <div
            style={{ color: "red", fontSize: "12px" }}
            dangerouslySetInnerHTML={{ __html: status?.msg }}
          />
        )}
        <div
          className={`clear-3 ${subscribeBtnClass ? subscribeBtnClass : ""}`}
        >
          <button className="btn btn-dark btn-sm btn-block" onClick={submit}>
            SUBSCRIBE
          </button>
        </div>
      </div>
    </div>
  );
};

const SubscribeEmail = ({ mailchimpUrl, spaceTopClass, subscribeBtnClass }) => {
  return (
    <div>
      <MailchimpSubscribe
        url={mailchimpUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            spaceTopClass={spaceTopClass}
            subscribeBtnClass={subscribeBtnClass}
          />
        )}
      />
    </div>
  );
};

SubscribeEmail.propTypes = {
  mailchimpUrl: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default SubscribeEmail;
