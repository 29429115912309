export const SEARCHED_DATA = "SEARCHED_DATA";

const fetchSearchData = (data) => ({
  type: SEARCHED_DATA,
  payload: data,
});

// fetch products
export const fetchSearchedData = (data) => {
  return (dispatch) => {
    dispatch(fetchSearchData(data));
  };
};
