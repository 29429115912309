export const ADD_TO_INQUIRY = "ADD_TO_INQUIRY";
export const DECREASE_QUANTITY = "DECREASE_DELETE_FROM_INQUIRYQUANTITY";
export const DELETE_FROM_INQUIRY = "DELETE_FROM_INQUIRY";
export const DELETE_ALL_FROM_INQUIRY = "DELETE_ALL_FROM_INQUIRY";
export const SET_INQUIRY_DATA = "SET_INQUIRY_DATA";

//add to cart
export const addToInquiry = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize
) => {
  return (dispatch) => {
    dispatch({
      type: ADD_TO_INQUIRY,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null,
      },
    });
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromInquiry = (item) => {
  return (dispatch) => {
    dispatch({ type: DELETE_FROM_INQUIRY, payload: item });
  };
};
//delete all from cart
export const deleteAllFromInquiry = (addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_INQUIRY });
  };
};
export const setInquiryData = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_INQUIRY_DATA, payload: data });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
};
