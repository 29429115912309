export const SET_IMAGE_DATA = "SET_IMAGE_DATA";

const selectedImage = (images) => ({
  type: SET_IMAGE_DATA,
  payload: images,
});
export const setImageData = (images) => {
  return (dispatch) => {
    dispatch(selectedImage(images));
  };
};
