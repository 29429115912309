export const SET_SELECTED_IMAGE = "SET_SELECTED_IMAGE";

const selectedImage = (products) => ({
  type: SET_SELECTED_IMAGE,
  payload: products,
});
export const setSelectedImage = (products) => {
  return (dispatch) => {
    dispatch(selectedImage(products));
  };
};
