export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";

const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});
const userLogoutSuccess = () => ({
  type: USER_LOGOUT,
});

// fetch products
export const fetchUser = (user) => {
  return (dispatch) => {
    dispatch(fetchUserSuccess(user));
  };
};
export const userLogout = () => {
  return (dispatch) => {
    dispatch(userLogoutSuccess());
  };
};
