import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import IconGroup from "./IconGroup";
import { publicRequest } from "../../axios/axios";
import { useHistory } from "react-router-dom";
import { fetchSearchedData } from "../../redux/actions/searchedData";

const HeaderTop = ({ }) => {
  const [categoryData, setcategoryData] = useState(null);
  const [selectedCat, setselectedCat] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [fromPrice, setFromPrice] = useState(0);
  const [suggetions, setsuggetions] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [toPrice, setToPrice] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setloading] = useState(false);

  const getSearchResults = async (e) => {
    e.preventDefault();
    setloading(true);
    let formData = {};
    if (searchText != "") {
      formData.searchString = selectedItem;
    }
    let price = {};
    if (fromPrice != 0) {
      price.from = parseInt(fromPrice);
    } else {
      price.from = parseInt(0);
    }
    if (toPrice != 0) {
      price.to = parseInt(toPrice);
    } else {
      price.to = parseInt(100000);
    }
    formData.price = price;
    if (selectedCat != null) {
      formData.mainCategoryId = parseInt(selectedCat);
    }
    formData.pageNumber = 1;
    dispatch(fetchSearchedData(formData));
    history.push(`/shop/searchResults=${selectedItem}`);
    setloading(false);
  };
  const autoCompleteSearch = async () => {
    await publicRequest
      .post("/product/autocomplete", { searchString: searchText })
      .then((res) => setsuggetions(res.data.data));
  };
  useEffect(() => {
    if (selectedItem == null) {
      autoCompleteSearch();
    }
  }, [searchText]);
  useEffect(() => {
    getCategory();
    return () => {
      setcategoryData([]);
    };
  }, []);
  const getCategory = () => {
    publicRequest
      .post("/mainCategory/categoryList", { isActive: 1 })
      .then((res) => {
        // console.log(res.data.data)
        setcategoryData(res.data.data)
      })
      .catch((e) => console.log(e));
  };
  const onChange = (id) => {
    setselectedCat(id);
  };
  return (
    <div className="container-fluid">
      <div className="row align-items-center bg-light py-2 px-xl-2 d-none d-lg-flex">
        <div className="col-lg-3 col-4 text-center">
          <a href="/">
            <img src="/assets/img/logo/logo.png" alt="" />
          </a>
        </div>
        <div className="col-lg-6 col-4 text-center">
          <form action="">
            <div className="input-group">
              <div className="SearchBar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for products"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={selectedItem}
                  style={{
                    borderTopLeftRadius: 18,
                    borderBottomLeftRadius: 18,
                    width: "358px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRightWidth: 0,
                  }}
                />
                {searchText !== "" && (
                  <ul>
                    {suggetions &&
                      suggetions.length > 0 &&
                      suggetions.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              setselectedItem(item.productName);
                              setsuggetions(null);
                            }}
                          >
                            <div className="row">
                              <div className="col-xl-9" style={{ margin: 0 }}>
                                {item.productName.slice(0, 35)}....
                              </div>
                              <div
                                className="col-xl-3"
                                style={{ margin: 0, padding: 0 }}
                              >
                                {item.productPrice == 0 ? (
                                  <>Ask for price</>
                                ) : (
                                  <> ₹{item.productPrice}</>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>

              <select
                className="form-control"
                aria-label="Default select example"
                style={{
                  width: "40px",
                  color: "grey",
                }}
                onChange={(e) => onChange(e.target.value)}
              >
                <option>Category</option>
                {categoryData &&
                  categoryData.map((item) => {
                    return (
                      <option
                        value={item.mainCategoryId}
                        key={item.mainCategoryId}
                      >
                        {item.productCategoryName}
                      </option>
                    );
                  })}
              </select>

              <input
                type="number"
                className="form-control"
                placeholder="From ₹"
                style={{
                  width: "10px",
                }}
                onChange={(e) => setFromPrice(e.target.value)}
              />
              <input
                type="number"
                className="form-control"
                placeholder="To ₹"
                style={{
                  width: "5px",
                }}
                onChange={(e) => setToPrice(e.target.value)}
              />
              {searchText === "" && fromPrice === 0 && toPrice === 0 ? (
                <button
                  className="btn btn-warning"
                  style={{
                    borderTopRightRadius: 18,
                    borderBottomRightRadius: 18,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  disabled
                >
                  <i className="fas fa-search"></i>
                </button>
              ) : (
                <button
                  className="btn btn-warning"
                  style={{
                    borderTopRightRadius: 18,
                    borderBottomRightRadius: 18,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  onClick={(e) => getSearchResults(e)}
                >
                  {loading ? (
                    <>
                      <div
                        className="spinner-border text-light spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <i className="fas fa-search"></i>
                    </>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="col-lg-3 col-3">
          <div className="header-right-wrap ">
            <p className="font-size mt-1">Customer Service</p>
            <h5 className="number mt-2 ml-2">
              <a href="tel:+919686202046">+91 9686202046</a>
            </h5>
            <h5 className="number mt-2 ml-2">
              <a href="/blog">Blog</a>
            </h5>
          </div>
          <IconGroup />
        </div>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func,
};

export default HeaderTop;
